<template>
  <div id="loginPage">
    <nav
      class="navbar navbar-expand-md navbar-dark bg-transparent"
      id="loginNav"
    >
      <div
        class="
          navbar-collapse
          collapse
          w-100
          order-1 order-md-0
          dual-collapse2
          ml-4
        "
      >
        <!-- <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="#">Left</a>
            </li>
        </ul> -->
        <img
          class=""
          alt="Mandaya Logo"
          style="height: 30px"
          src="../assets/careDokterWhite.png"
        />
      </div>
      <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 mr-4">
        <ul class="navbar-nav ml-auto" id="masukDaftar">
          <li class="nav-item">
            <a
              :class="'nav-link ' + route(1)"
              href="#"
              @click.prevent="routeChange('LoginPage')"
              >Masuk</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link ' + route(2)"
              href="#"
              @click.prevent="routeChange('RegisterPage')"
              >Daftar</a
            >
          </li>
        </ul>
      </div>
    </nav>

    <div class="d-flex justify-content-start pt-3">
      <img
        class="imageBar"
        alt="Mandaya Logo"
        style="height: 4rem"
        src="../assets/careDokterWhite.png"
      />
    </div>
    <!-- <div class="container mt-5 pt-5"> -->
    <div class="row p-5 firstRow">
      <div class="col-12 col-sm-6 mt-3 text-left welcomeText">
        <h1><b>Selamat Datang</b></h1>
        <h1><b>Sahabat Mandaya!</b></h1>
        <div class="des-log">
          <h5>Jika anda tidak memiliki akun</h5>
          <h5>
            Anda dapat mendaftar di
            <b
              class="c-pointer"
              @click.prevent="$router.push({ name: 'RegisterPage' })"
              ><u> sini</u></b
            >
          </h5>
        </div>
      </div>

      <router-view />

      <!-- lupa password -->

      <!-- konfirmasi reset password -->
    </div>
    <!-- </div> -->

    <div class="bottomBar">
      <div class="d-flex justify-content-center">
        <a
          :class="'nav-link ' + route(1)"
          href="#"
          @click.prevent="routeChange('LoginPage')"
          >Masuk</a
        >
        <a
          :class="'nav-link ' + route(2)"
          href="#"
          @click.prevent="routeChange('RegisterPage')"
          >Daftar</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
export default {
  data() {
    return {
      loginForm: "login",
      password: "",
      userName: "",
      accessToken: "",
      refreshToken: "",
      kategories: [],
      kategori: "",
      email: "",
      namaDepan: "",
      namaBelakang: "",
      password: "",
      konfirmasiPassword: "",
      persetujuanEula: true,
      telepon: "",
      tipeAlamat: "",
      alamat: "",
      jalan: "",
      Konpassword: "",
      kodeVer: "",
      regisId: "",
      loading: false,
      isDisabled: false,
    };
  },
  watch: {},
  methods: {
    routeChange(status) {
      this.$router.push({ name: status }).catch((err) => {});
    },
    checkClassWithRoute(route) {
      if (route == "LoginPage") return 1;
      else if (route == "RegisterPage") return 2;
      return 0;
    },
    nameKeydown(e) {
      // console.log(/^[^\W_]+$/.test(e.key));
      // console.log(/^[_.@a-z0-9-]*$/.test(e.key));
      if (/^[_.@a-z0-9-]*$/.test(e.key)) {
      } else {
        e.preventDefault();
      }
    },
    route(rut) {
      if (rut == this.checkClassWithRoute(this.$route.name)) {
        return "active";
      }
      return "";
    },

    getauth() {
      axios
        .get(`/api/auth/true`, {
          // withCredentials: true,
          headers: {
            // "cookie": `access_token=${this.accessToken}; session_token=${this.refreshToken}`,
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
        .then(({ data }) => {
          this.getToken();
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getToken() {
      axios
        .get(`/mandayauaa/api/account`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
        .then(({ data }) => {
          this.$router.push("/");
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    whichForm(form) {
      this.loginForm = form;
    },
    loging() {
      this.$router.push("/");
    },
  },
  mounted() {
    if (localStorage.errorToken) {
      this.$toast.error(localStorage.errorToken);
      localStorage.removeItem("errorToken");
    }
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
@media screen and (max-height: 500px) {
  .bottomBar {
    display: none !important;
  }
}
#loginPage {
  background: rgb(47, 144, 176);
  background: linear-gradient(39deg, rgb(176 47 120) 0%, rgb(64 10 199) 100%);
  min-height: 100vh;
}

.welcomeText h1,
.welcomeText h5 {
  color: whitesmoke;
}

.active {
  border-bottom: 5px solid whitesmoke;
}
</style>
